















import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import GlobalErrorSnackBar from "@/components/GlobalErrorSnackBar.vue";
import Loading from "@/components/Loading.vue";

export default Vue.extend({
  components: { GlobalErrorSnackBar, Loading },
  props: ["title", "settingKey", "lang"],
  data() {
    return {
      loading: false,
      data: null,
    };
  },
  computed: {
    content() {
      if (!this.data) {
        return;
      }
      const item = this.data[this.settingKey];
      if (!item || !item.length) {
        return;
      }
      const languageItem = item.find(({ language }) => language === ( this.lang || "en"));
      if (!languageItem) {
        return;
      }
      return languageItem.content;
    },
  },
  async created() {
    this.loading = true;
    this.data = await coreApiClient.call("setting", "get");
    this.loading = false;
  },
});
